
































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AutocompleteInput from '@/components/form/AutocompleteInput.vue';
import { AdminService } from '@/lib/services';

import { AccountantExt, CompanyExt } from '@/models';

@Component({
  components: {
    AutocompleteInput,
  },
})
export default class AccountantCompanyLinkModal extends Vue {
  @Prop({ default: () => new AccountantExt(), type: AccountantExt })
  accountant: AccountantExt;

  @Prop({ default: () => new CompanyExt(), type: CompanyExt })
  company: CompanyExt;

  @Prop({ default: false, type: Boolean })
  editing: boolean;

  @Prop({ required: true })
  handler: (accountant: AccountantExt, company: CompanyExt) => Promise<number>;

  async getCompanies(search: string): Promise<CompanyExt[]> {
    const companies = await AdminService.getCompanies(0, search);
    return companies.items;
  }

  onCompanySelect(company: CompanyExt): void {
    this.company.id = company.id;
    this.company.name = company.name;
  }

  async onSubmit(): Promise<void> {
    await this.handler(this.accountant, this.company);
    this.$emit('saved', this.accountant);
  }
}
