














































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { CompanyExt } from '@/models';
import { AdminService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import AccountantList from '@/components/admin/AccountantList.vue';
import AccountantEditModal from '@/components/modals/AccountantEditModal.vue';
import Fab from '@/components/Fab.vue';
import { AccountantExt } from '@/models/AccountantExt';
import AccountantCompanyLinkModal from '@/components/modals/AccountantCompanyLinkModal.vue';

@Component({
  components: {
    AccountantCompanyLinkModal,
    AccountantList,
    AccountantEditModal,
    Fab,
  },
})
export default class AdminAccountantsPage extends Vue {
  loading: boolean = true;
  accountants: AccountantExt[] = [];

  editingAccountant: AccountantExt | null = null;
  newAccountant: AccountantExt | null = null;
  showLinkCompanyAccountantModal: boolean | false = false;

  get connectedTotal(): number {
    return this.accountants.reduce(
      (acc, e) => (acc += parseInt(e.companies)),
      0,
    );
  }

  async created(): Promise<void> {
    await this.refresh();
  }

  async refresh(): Promise<void> {
    this.loading = true;
    try {
      this.accountants = await AdminService.getAccountants();
    } catch (e) {
      this.$toaster.error('Error loading companies', unwrapError(e));
    } finally {
      this.loading = false;
    }
  }

  onEdit(c: AccountantExt): void {
    this.editingAccountant = c;
  }

  startNewAccountant(): void {
    this.newAccountant = new AccountantExt();
  }

  linkCompanyAccountant(): void {
    this.showLinkCompanyAccountantModal = true;
  }

  async onLinkCompanyAccountant(
    a: AccountantExt,
    c: CompanyExt,
  ): Promise<void> {
    try {
      await AdminService.linkCompanyAccountant(a, c);
      this.$toaster.success(
        this.$tc('messages.success.linked.company'),
        c.id.toString(),
      );
      this.showLinkCompanyAccountantModal = false;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.link.company'),
        unwrapError(e),
      );
    }
  }

  async onNewComplete(a: AccountantExt): Promise<void> {
    try {
      await AdminService.createAccountant(a);
      this.$toaster.success(
        this.$tc('messages.success.created.company'),
        a.name,
      );
      this.newAccountant = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.create.company'),
        unwrapError(e),
      );
    }
  }

  async onEditComplete(a: AccountantExt): Promise<void> {
    try {
      await AdminService.updateAccountant(a);
      this.$toaster.success(
        this.$tc('messages.success.update.company'),
        a.name,
      );
      this.editingAccountant = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.company'),
        unwrapError(e),
      );
    }
  }
}
