

















































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { AccountantExt } from '@/models';
import { Language } from '@/plugins/i18n';

@Component({
  data() {
    return { Language };
  },
})
export default class AccountantEditModal extends Vue {
  @Prop({ default: () => new AccountantExt(), type: AccountantExt })
  accountant: AccountantExt;

  @Prop({ default: false, type: Boolean })
  editing: boolean;

  @Prop({ required: true })
  handler: (accountant: AccountantExt) => Promise<number>;

  @Prop({ default: false })
  detachHandler: (accountant: AccountantExt) => Promise<void>;

  async onSubmit(): Promise<void> {
    await this.handler(this.accountant);
    this.$emit('saved', this.accountant);
  }
}
