






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Accountant } from '@/models';

@Component
export default class AccountantList extends Vue {
  @Prop({ required: true })
  accountants: Accountant[];

  @Prop({ type: Array, default: () => ['name', 'companies', 'status'] })
  columns: string[];

  columnDefinitions = {
    id: { width: 1 },
    name: { width: 6 },
    companies: { width: 2 },
    status: { width: 2 },
  };
}
