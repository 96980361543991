var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accountant-list"},[_c('div',{staticClass:"fl-row tni-row--header"},[_vm._l((_vm.columns),function(column,idx){
var _obj;
return _c('div',{directives:[{name:"t",rawName:"v-t",value:('accountant.' + column),expression:"'accountant.' + column"}],key:idx,class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions[column].width] = true, _obj )})}),_c('div',{staticClass:"fl-zero fl-grow-2"},[_vm._v("Actions")])],2),_vm._l((_vm.accountants),function(accountant){return _c('div',{key:accountant.id,staticClass:"tni-row fl-row"},[_vm._l((_vm.columns),function(column,idx){
      var _obj;
return _c('div',{key:idx,class:( _obj = {
        'fl-zero': true
      }, _obj['fl-grow-' + _vm.columnDefinitions[column].width] = true, _obj ),domProps:{"textContent":_vm._s(accountant[column])}})}),_c('div',{staticClass:"fl-zero fl-grow-2"},[_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.$emit('edit', accountant)}}},[_vm._v(" Edit ")])])],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }